import React, { FC } from 'react'
import { HiPlus, HiMinus } from 'react-icons/hi'

interface Props {
  question: string
  answer: string
  open?: boolean
}

const Question: FC<Props> = ({ question, answer, open }) => {
  return (
    <details
      id={encodeURIComponent(question)}
      open={open}
      className="group py-5 border-x-gray-400 dark:border-b-gray-400 border-b-1"
    >
      <summary className="summary cursor-pointer">
        <p className="inline-block subtitle font-medium group-open:font-bold">
          <span className="group-open:hidden inline-block align-text-bottom mr-2">
            <HiPlus size={24} />
          </span>
          <span className="hidden group-open:inline-block align-text-bottom mr-2">
            <HiMinus size={24} />
          </span>
          {question}
        </p>
      </summary>
      <p className="body-1 text-gray-400 dark:text-gray-300 pt-4">{answer}</p>
    </details>
  )
}

export default Question
