import classNames from 'classnames'
import type { HeadFC, PageProps } from 'gatsby'
import React, { useMemo } from 'react'
import { useIntl } from 'react-intl'

import { LanguageHeaders } from '../components/LanguageHeaders'
import Layout from '../components/Layout'
import Link from '../components/Link'
import Question from '../components/Question'
import translations from '../translations'
import { useTranslate } from '../utils/translate'

// TODO: set visible section based on url params
const FaqPage = ({ location }: PageProps) => {
  const params = new URLSearchParams(location.search)
  const visibleSectionParam = params.get('section') || null
  const openQuestionParam = location.hash ? decodeURIComponent(location.hash.substring(1)) : null

  const t = useTranslate()
  // @ts-ignore
  const allFaqs: typeof translations['en']['faq.all'] = useIntl().messages['faq.all']
  const section = useMemo(() => {
    if (!allFaqs) {
      return null
    }
    if (!visibleSectionParam) {
      return allFaqs[0]
    }

    const _faqs = allFaqs.find((faqs) => faqs.title.toLowerCase() === visibleSectionParam.toLowerCase())
    if (_faqs) {
      return _faqs
    }
  }, [visibleSectionParam, allFaqs])

  return (
    <Layout light>
      <main className="m-auto w-full xl:max-w-screen-xl px-4 xl:px-0 py-28 grid grid-flow-row gap-28">
        <h2 className="text-center">{t('faq.title')}</h2>
        <div className="flex flex-col md:flex-row items-baseline">
          <aside className="md:mr-10 mb-10 uppercase grid grid-flow-row gap-2 min-w-fit">
            {allFaqs.map((faq, index) => (
              <Link
                key={index}
                rel="nofollow"
                to={`/faq?${new URLSearchParams([['section', faq.title]]).toString()}`}
                className={classNames(
                  'subtitle font-semibold cursor-pointer hover:underline underline-offset-4 text-gray-300',
                  {
                    ['font-bold text-gray-600']: faq.title.toLowerCase() === visibleSectionParam?.toLowerCase(),
                  }
                )}
              >
                {faq.title}
              </Link>
            ))}
          </aside>
          <section className="grow">
            {section && (
              <>
                <h3>{section.title}</h3>
                {section.faqs.map((faq) => (
                  <Question
                    question={faq.question}
                    answer={faq.answer}
                    open={openQuestionParam?.toLowerCase() === faq.question.toLowerCase() ? true : undefined}
                    key={faq.question}
                  />
                ))}
              </>
            )}
          </section>
        </div>
      </main>
    </Layout>
  )
}

export default FaqPage

export const Head: HeadFC = ({ pageContext }: any) => {
  const language = pageContext.language
  const translations: any = pageContext.translations
  return (
    <>
      <title>{translations['faq.meta.title']}</title>
      <meta name="description" content={translations['faq.meta.description']} />
      <meta name="robots" content="index, follow" />
      <LanguageHeaders pathname={pageContext.defaultPath} locale={language} />
    </>
  )
}
